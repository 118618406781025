@font-face {
    font-family: "Nunito Bold";
    src: url('/fonts/Nunito/static/Nunito-Bold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "Nunito Regular";
    src: url('/fonts/Nunito/static/Nunito-Regular.ttf') format('truetype');
    font-display: swap;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Nunito', sans-serif;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

