.footerContainer:before {
  width: 100%;
  height: 212px;
  background-image: url('/images/footer-top.svg');
  content: '';
  position: absolute;
  background-repeat: no-repeat;
  left: 0;
  right: 0;
  top: -212px;
  background-size: cover;
}

.footerContainer {
  position: relative;
  width: 100%;
  background: linear-gradient(to top, #131A53, #244F9D);
  background-repeat: no-repeat;
  margin-top: 80px;
}

.footerInnerContainer {
  position: relative;
  z-index: 1;
  color: #fff;
}

.footerInnerTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #D2DAE8;
  padding: 20px 0;
}

.footerCopyRights {
  text-align: center;
  color: #fff;
  width: 100%;
  padding: 10px 0;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
  margin-bottom: 30px;
}

.footerItem{
  display: flex;
  flex-direction: column;
}

.footerItemDetails{
  font-size: 1.2rem;
  line-height: 25px;
  margin-bottom: 10px;
}

.footerItemHeader{
  font-size: 1.5rem;
  line-height: 28px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #fff;
}

.socials {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  width: 350px;
  color: #D2DAE8;
  font-size: 16px;
  line-height: 30px;

  :global {
    .MuiButtonBase-root {
      color: #fff;

      svg {
        font-size: 50px;
      }
    }
  }
}

/* tablets */
@media only screen and (max-width: 1260px) {
  .footerInnerTop h6 {
    font-size: 20px !important;
  }

  .footerInnerTop p {
    font-size: 15px !important;
  }

  .footerInnerTop p,  .footerInnerTop a {
    font-size: 15px !important;
  }

  .footerInnerTop svg{
    font-size: 30px !important;
  }

  .footerInnerTop{
    padding: 20px;
    justify-content: space-evenly;
  }
}

/* mobiles */
@media only screen and (max-width: 900px) {
  .footerContainer:before{
    height: 90px;
    top: -60px;
  }

  .footerInnerTop{
    padding: 5px;
    flex-direction: column;
    justify-content: center;

  }

  .footerItem {
    font-size: 20px !important;
    margin-bottom: 20px;
  }

  .socials {
    margin-top: 10px;
    width: inherit;
    justify-content: center;

    :global {
      .MuiButtonBase-root {
        color: #fff;
        padding: 0px 20px 0 0;
        margin-bottom: 20px;

        svg {
          font-size: 50px;
        }
      }
    }
  }

  .footerInnerTop h6 {
    font-size: 20px !important;
  }

  .footerInnerTop p {
    font-size: 18px !important;
  }

  .footerInnerTop p,  .footerInnerTop a {
    font-size: 18px !important;
  }

  .footerInnerTop svg{
    font-size: 30px !important;
  }

  .footerCopyRights{
    //width:100%;
    padding:10px 60px;
  }
}