/* LetsGetStarted.module.css */

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0 20px 0;
    border-bottom: 1px solid #5A7BB4;
    margin-bottom: 40px;
}

.content h2 {
    margin: 0;
    padding: 0;
    font-size: 2.5rem;
    line-height: 60px;
    color: #fff;
}

.content p {
    margin-top: 20px;
    padding: 0;
    font-size: 1.2rem;
    line-height: 30px;
    color: #D2DAE8;
    width: 513px;
}

.button {
    padding: 20px 30px;
    background-color: #fff;
    border: none;
    border-radius: 5px;
    color: #2758AD;
    cursor: pointer;
    font-size: 1em;
}


/* mobiles */
@media only screen and (max-width: 900px) {
    .container{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .container h2 {
        font-size: 1.2rem;
    }

    .container p {
        font-size: 1rem;
        width: 100%;
    }

    .button {
        padding: 10px 15px;
    }
}